<template>
	<div class="home">
		<div class="home-top-info">
			<el-carousel trigger="click" height="500px" indicator-position="none" :interval="5000" arrow="never"
				ref="cardShow" style="height: 100%;">
				<el-carousel-item v-for="item in 3" :key="item" style="height: 100%;">
					<el-image style="width: 100%;height: 100%;" :src="require(`@/assets/hoem-nav-item${item}.png`)"
						fit="cover"></el-image>
				</el-carousel-item>
			</el-carousel>
			<div class="home-nav-arrow">
				<img src="@/assets/home-nav-arrow-left.png" alt="" @click="arrowClick('left')" />
				<img src="@/assets/home-nav-arrow-right.png" alt="" @click="arrowClick('right')" />
			</div>
		</div>
		<div class="home-about">
			<div class="about-us">
				<div class="about-us-title">
					<h2>大骏科技简介</h2>
					<p>ABOUT US</p>
				</div>
				<p>湖南大骏科技有限公司成立于2019年，办公地址为长沙市人民东路二段先进储能节能创意示范产业园15栋。拥有先进的生产加工、产品开发和试验检测设备。公司立足于自主研发生产，立志成为国内散装物料设备专业厂商。
					公司现有员工20余人，其中本科和中级职称以上人员占90%，类专业技术人员10多人，均为散装物料设备行业资深从业人员，从事散装物料输送、清理、采样设备行业均超十年以上。</p>
				<p>大骏科技坚持“高品质产品赢得市场”理念。公司集强大的软、硬件产品开发实力于一体，现有装备融合和集成了信息、网络、传感、软件等多种技术，满足散装物料处理行业大规模、快节奏、数字化、智能化的发展要求。主要产品有:(火车、汽车、皮带)采样机、机器人制样机系统、样品封装系统、淸箅破碎机、混合机、叶轮给煤机、螺旋卸车机、板式给料机、松动机、燃料信息化管理系统等。广泛服务于国内火电、焦化、钢铁、石化、水泥、煤化工、粮食等消耗和储运大宗散装物料的行业。
					大骏科技拥有丰富的各类散装物料现场经验，我们希望以务实的态度，可靠的产品，满意的服务为广大的散装物料行业贡献力量。</p>
			</div>
			<div class="about-list">
				<ul>
					<li v-for="(item,index) in homeArr" :key="index">
						<img :src="require(`@/assets/businessArea-list-img${index+1}.png`)" />
						<h3>{{item.title}}</h3>
						<div @click="onRouter(item.path)"></div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomeView',
		data() {
			return {
				homeArr: [{
					img: "",
					title: '智能火车采样机系列',
					path: "/businessArea/1",
				}, {
					img: "",
					title: '智能汽车采样机系列',
					path: "/businessArea/3",
				}, {
					img: "",
					title: '智能皮带采制样机系列',
					path: "/businessArea/10",
				}, {
					img: "",
					title: '智能型样品封装系统',
					path: "/businessArea/10",
				}]
			}
		},
		methods: {
			arrowClick(val) {
				if (val === 'right') {
					this.$refs.cardShow.next()
				} else {
					this.$refs.cardShow.prev()
				}
			},
			onRouter(path) {
				this.$router.replace(path)
				document.documentElement.scrollTop = 0;
			}
		},
	}
</script>

<style lang="less">
	.home {
		width: 100%;

		.home-top-info {
			position: relative;
			margin: auto;
			height: 500px;

			.el-carousel__container {
				height: 100%;
			}

			.el-carousel {
				.el-carousel__item {
					width: 100%;

					img {
						width: 100%;
						height: 500px;
					}
				}
			}

			.home-nav-arrow {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 100%;
				height: 500px;
				z-index: 10;

				img {
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 60px;
					height: 135px;
					cursor: pointer;
				}

				img:nth-child(1) {
					left: 0;
				}

				img:nth-child(2) {
					right: 0;
				}
			}
		}

		.home-about {
			display: flex;
			flex-direction: column;
			align-items: center;


			>img {
				width: 100%;
				height: 820px;
			}

			.about-us {
				width: 1200px;
				display: flex;
				justify-content: space-between;
				margin-top: 40px;

				.about-us-title {
					width: 25%;
					letter-spacing: 2px;

					h2 {
						font-weight: bold;
						font-size: 36px;
						margin-bottom: 10px;
					}

					>p {
						font-size: 22px;
					}
				}

				>p {
					font-size: 18px;
					width: 30%;
				}
			}

			.about-list {
				padding-bottom: 46px;
				width: 1200px;
				margin-top: 29px;

				ul {
					display: flex;
					justify-content: space-between;
					// margin: 0 106px;

					li {
						text-align: center;

						img {
							width: 240px;
							height: 190px;
						}

						h3 {
							font-weight: bold;
							font-size: 20px;
							margin: 13px auto;
						}

						div {
							width: 178px;
							height: 53px;
							margin: auto;
							background-image: url('/src/assets/home-about-list-li-but.png');
							background-size: 100% 100%;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>