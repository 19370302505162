<template>
	<div id="app">
		<div class="pc" v-if="flag==null">
			<div class="guanwan-info" id="target">
				<div class="logoName">
					<img src="@/assets/Logo1.png" alt="" title="图片logo" />
					<div class="namInfo">
						<span>湖南大骏科技有限公司</span>
						<span>HUNAN DAJUN TECHNOLOGY CO.,LTD</span>
					</div>
				</div>
				<div class="information">
					<h3>联系我们</h3>
					<div class="">
						<div class="" @click="callPhone('18684987690')">
							<img src="@/assets/phone-icon.png" />
							<span>18684987690</span>
						</div>
						<div class="">
							<a href="mailto:278495689@qq.com">
								<img src="@/assets/mail-icon.png" alt="" />
								<span>278495689@qq.com</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="all-top" style="">
				<div class="nav">
					<div class="nav-ul">
						<ul>
							<li v-for="(item,index) in navList" :key="index">
								<!-- <router-link :to="item.path">{{item.name}}</router-link> -->
								<router-link :to="{path: item.path}" replace
									@click.native="refresh">{{item.name}}</router-link>
								<div class="subItems">
									<ul>
										<li v-for="(item2,index2) in item.subItems" :key="index2">
											<router-link :to="item2.path" @click.native="refresh">
												<span>{{item2.name}}</span><span>&GT;</span>
											</router-link>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
					<div class="nav-img" @click="onSearch(true)">
						<img src="@/assets/search-icon.png" @dragstart.prevent alt="" />
					</div>
				</div>
			</div>
			<router-view :key="$route.fullPath" />
			<Footer></Footer>
			<div class="pofixed" :class="{suo,}">
				<div>
					<div><img src="@/assets/phone-icon-white.png" />
						<p @click="callPhone('15874935956')">彭经理：15874935956</p>
					</div>
					<div><img src="@/assets/phone-icon-white.png" />
						<p @click="callPhone('18684987690')">刘经理：18684987690</p>
					</div>
				</div>
				<div>
					<img src="@/assets/pofixed-suo.png" @click="suo=!suo"
						:style="{transform: suo?'rotate(0deg)':'rotate(180deg)',}" />
				</div>
			</div>
			<div class="app-footer">
				<p>Copyright © 2024 湖南大骏科技有限公司</p>
				<p @click="nofollow('https://beian.miit.gov.cn/#/Integrated/index')">湘ICP备2024059869号-1</p>
				<!-- <p @click="window.open('http://www.hunandajun.top/img/zzry-img1.4bea5e73.png', '_blank')">营业执照</p> -->
				<p @click="nofollow('http://www.hunandajun.top/img/zzry-img2.74a4c097.png')">营业执照</p>
			</div>
			<div class="searchBox" v-if="searchChang">
				<div class="searchView">
					<div>
						<img src="@/assets/searchImg.png" alt="" />
					</div>
					<div>
						<input type="text" placeholder="输入关键词" v-model="SearchTxt" />
						<span @click="onSearch('Search')">搜索</span>
					</div>
					<div>
						关键词:智能火车采样机系列、散装物料及其处理、板式给矿机、双轴式混合机系列
					</div>
					<img @click="onSearch(false)" src="@/assets/searchClose.png" alt="" />
				</div>
			</div>
		</div>
		<div class="mobile" v-if="flag">
			<div class="relation" @click="callPhone('18684987690')">24小时资讯热线：刘经理186-8498-7690</div>
			<div class="navigation" id="stickyElement">
				<div class="">
					<img src="@/assets/mobile/showNav.png" alt="" @click="p_nav=!p_nav" />
				</div>
				<div class="">
					大骏科技
				</div>
				<div class="">
					<img src="@/assets/search-icon.png" alt="" @click="centerDialogVisible = true" />
				</div>
				<div class="p_navigation " :style="{display: p_nav?'block':'none',}">
					<el-collapse class="el-collapse" v-model="activeNames" @change="handleChange">
						<el-collapse-item v-for="(item,index) in navList" :key="index" :title="item.name"
							style="padding: 0 20px;" :name="index+1" :disabled="item.subItems.length<=0">
							<template slot="title">
								<router-link :to="{path: item.path}" replace
									@click.stop.native="refresh;p_nav=false;activeNames=[]">{{item.name}}</router-link>
							</template>
							<div>
								<ul>
									<li v-for="(item2,index2) in item.subItems" :key="index2">
										<router-link :to="{path: item2.path}" replace
											@click.native="refresh;p_nav=false;activeNames=[]">{{item2.name}}</router-link>
									</li>
								</ul>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>
			<router-view :key="$route.fullPath" />
			<div class="mob-footer">
				<p>Copyright © 2024 湖南大骏科技有限公司</p>
				<p @click="nofollow('https://beian.miit.gov.cn/#/Integrated/index')">湘ICP备2024059869号-1</p>
				<p @click="nofollow('http://www.hunandajun.top/img/zzry-img2.74a4c097.png')">营业执照</p>
			</div>
			<el-dialog :visible.sync="centerDialogVisible" width="90%" center modal :show-close="false">
				<div class="searchPage">
					<div>
						<img src="@/assets/searchImg.png" alt="" />
					</div>
					<div>
						<input type="text" placeholder="输入关键词" v-model="SearchTxt" />
						<span @click="onSearch('Search')">搜索</span>
					</div>
					<div>
						关键词:智能火车采样机系列、散装物料及其处理、板式给矿机、双轴式混合机系列
					</div>
					<img @click="onSearch(false);centerDialogVisible = false" src="@/assets/searchClose.png" alt="" />
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import Footer from './components/footer'
	export default {
		name: 'App',
		components: {
			Footer
		},
		data() {
			return {
				window,
				navList: [{
						name: '网站首页',
						path: '/',
						subItems: [],
					},
					{
						name: '关于大骏',
						path: '/aptitudeHonor',
						subItems: [],
						/* subItems: [{
								path: '/about',
								name: '营业执照1'
							},
							{
								path: '/aptitudeHonor/',
								name: '营业执照'
							}, {
								path: '/aptitudeHonor/',
								name: '营业执照'
							}, {
								path: '/aptitudeHonor/',
								name: '营业执照'
							}, {
								path: '/about/',
								name: '营业执照'
							},
						] */
					}, {
						name: '业务领域',
						path: '/businessArea',
						subItems: [{
								path: '/businessArea',
								name: '散装物料及其处理'
							},
							{
								path: '/businessArea/1',
								name: '智能火车采样机系列'
							}, {
								path: '/businessArea/2',
								name: '定量叶轮给料（焦）机系列/板式给矿机'
							}, {
								path: '/businessArea/3',
								name: '智能汽车采样机系列'
							}, {
								path: '/businessArea/4',
								name: '智能成套装备与技术/松动机系列'
							}, {
								path: '/businessArea/5',
								name: '定量圆盘给料系统'
							}, {
								path: '/businessArea/6',
								name: '双轴式混合机系列/叶轮给煤机（双侧式叶轮给煤机）'
							}, {
								path: '/businessArea/7',
								name: '给料式破碎机系列/螺旋卸车机系列'
							}, {
								path: '/businessArea/8',
								name: '智能制样机系统/清算破碎机系列'
							}, {
								path: '/businessArea/9',
								name: '无人值守采样系统'
							}, {
								path: '/businessArea/10',
								name: '智能皮带采制样机系列/智能型样品封装系统'
							},
						]
					},
					/* {
						name: '参与业绩',
						path: '/performance',subItems: [],
						subItems: [{
								path: '/about',
								name: '采样机系列'
							},
							{
								path: '/performance/',
								name: '采样机系列'
							}, {
								path: '/performance/',
								name: '采样机系列'
							}, {
								path: '/performance/',
								name: '采样机系列'
							}, {
								path: '/about/',
								name: '采样机系列'
							},
						]
					}, */
					{
						name: '联系我们',
						path: '/contact',
						subItems: [],
					}, {
						name: '客户留言',
						path: '/message',
						subItems: [],
					},
					/* {
						name: '新闻动态',
						path: '/newsFeed',subItems: [],
					}, {
						name: '人才招聘',
						path: '/recruitment',subItems: [],
					} */
				],
				searchChang: false,
				SearchTxt: "",
				suo: true,
				flag: null,
				p_nav: false,
				activeNames: [],
				centerDialogVisible: false,
			}
		},
		created() {
			this.flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			if (this.flag) {
				console.log('13：', this.flag);
			} else {
				console.log(false);
			}
		},
		methods: {
			callPhone(phoneNum) {
				window.location.href = 'tel://' + phoneNum
			},
			nofollow(path) {
				window.open(path, '_blank')
			},
			refresh() {
				document.documentElement.scrollTop = 0;
			},
			onSearch(item) {
				if (item == 'Search') {
					if (this.SearchTxt.trim().length > 0) {
						console.log('搜索成功');
						this.$router.push({
							path: '/search',
							query: {
								keywords: this.SearchTxt
							}
						})
						this.centerDialogVisible = false
						document.documentElement.scrollTop = 0;
						this.searchChang = false
						this.p_nav = false
						this.SearchTxt = ""
					}
				} else {
					this.searchChang = item
				}
			},
			handleChange(val) {
				console.log(val);
			},
		},
	}
</script>
<style lang="less">
	@import url('assets/css/All.css');

	body {
		background-color: #FFF;
	}

	#app {
		/* 	display: flex;
		flex-direction: column;
		align-items: center; */
	}

	.pc {


		.all-top {
			position: sticky;
			top: 0;
			z-index: 9999999;
			width: 100%;
			background-color: #137AC2;
		}

		.guanwan-info {
			width: 1200px;
			height: 100px;
			background-color: #fff;
			color: #000;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			margin: auto;

			.logoName {
				// margin-left: 360px;
				display: flex;
				height: 100%;
				align-items: center;

				>img {
					width: 108px;
					height: 78px;
				}

				.namInfo {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: relative;
					height: 71px;
					margin-left: 53px;
					user-select: none;

					&:before {
						height: 71px;
						width: 2px;
						background-color: #CDCDCD;
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						left: -25px;
						margin: auto;
					}

					span:nth-child(1) {
						font-size: 16px;
						font-weight: bold;
						padding-top: 11px;
						letter-spacing: 0.8px;
					}

					span:nth-child(2) {
						font-size: 11px;
						color: #6F6F6F;
						padding-bottom: 11px;
						letter-spacing: 0.5px;
					}
				}

			}

			.information {
				// margin-right: 360px;
				height: 55px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h3 {
					font-size: 18px;
					font-weight: bold;
				}

				>div {
					display: flex;

					>div {
						color: #137AC2;
						display: flex;
						align-items: center;
						font-size: 14px;
						cursor: pointer;

						img {
							width: 16px;
							height: 16px;
						}

						span {
							letter-spacing: 0.7px;
							margin-left: 4px;
							color: #137AC2;
						}
					}

					>div:nth-child(2) {
						margin-left: 50px;

						img {
							width: 16px;
							height: 13px;
						}
					}
				}
			}
		}

		.nav {
			width: 1200px;
			height: 71px;
			display: flex;
			align-items: center;
			// padding: 0 360px;
			margin: auto;

			.nav-ul {
				// margin-left: 32px;
				width: calc(100vw - 154px);
				text-align: center;

				>ul {
					display: flex;
					justify-content: flex-end;
					font-size: 20px;
					width: 100%;

					>li {
						font-size: 20px;
						font-weight: bold;
						cursor: pointer;
						position: relative;
						flex-grow: 1;
						height: 71px;
						line-height: 71px;

						>a {
							// padding: 0 28px;
							color: #60B3ED;
							position: relative;
							z-index: 10;
							display: inline-block;
							width: 100%;
						}

						&:hover {
							>a {
								color: #fff;
							}

							&::after {
								content: '';
								width: 50%;
								height: 3px;
								background-color: #fff;
								position: absolute;
								left: 0;
								right: 0;
								bottom: 5px;
								margin: auto;
								z-index: 50;
							}

							&:before {
								position: absolute;
								content: '';
								width: 100%;
								height: 71px;
								background-color: #096EB5;
								bottom: 0;
								left: 0;
								right: 0;
								margin: auto;
								color: #fff !important;
								z-index: 5;
								animation: slidein .5s;
							}

							@keyframes slidein {
								0% {
									height: 0;
								}

								100% {
									height: 100%;
								}
							}

							.subItems {
								display: block !important;
							}
						}

						.subItems {
							position: absolute;
							top: 71px;
							left: 0;
							margin: auto;
							width: 150%;
							display: none;

							>ul {
								display: flex;
								flex-direction: column;
								width: 130%;
								background-color: rgba(236, 244, 249, .9);
								// background-color: #54edf5;

								>li {
									display: flex;
									align-items: center;
									min-height: 40px;
									width: 100%;
									justify-content: space-between;
									overflow: hidden;

									>a {
										padding: 0 12px;
										font-size: 16px;
										color: #000;
										display: inline-block;
										width: 100%;

										span {
											height: 40px;
											line-height: 40px;
										}

										span:nth-child(1) {
											float: left;
										}

										span:nth-child(2) {
											float: right;
										}
									}
								}
							}
						}
					}
				}
			}

			.nav-img {
				width: 154px;
				text-align: center;
				cursor: pointer;

				>img {
					width: 24px;
					height: 24px;
					-moz-user-select: -moz-none;
					-khtml-user-select: none;
					-webkit-user-select: none;
					-o-user-select: none;
					user-select: none;
				}
			}

		}

		.pofixed {
			width: 340px;
			height: 240px;
			position: fixed;
			left: 0;
			top: 400px;
			bottom: 0;
			margin: auto;
			z-index: 9999;
			display: flex;
			align-items: center;
			overflow: hidden;
			transform: translateX(-300px);
			transition: .1s;

			&.suo {
				transform: translateX(0);
				transition: .1s;
				// transform: translateX(-300px);
			}

			/* transform: translateX(-280px);

		&:hover {
			// width: 50px;
			
		} */

			>div:nth-child(1) {
				>div {
					width: 294px;
					height: 55px;
					line-height: 55px;
					display: flex;
					align-items: center;
					margin: 24px auto;
					background-image: url('@/assets/pofixed-phone-bg.png');
					background-size: 100% 100%;

					p {
						cursor: pointer;
					}

					>img {
						width: 23px;
						height: 24px;
						margin-right: 10px;
						margin-left: 30px;
					}

					font-size: 18px;
					color: #fff;
				}
			}

			>div:nth-child(2) {
				width: 40px;
				height: 40px;
				margin-left: 8px;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}
			}


		}

		.app-footer {
			display: flex;
			justify-content: center;
			padding: 20px 15px;
			text-align: center;
			background-color: #0348a6;
			color: #fff;

			p {
				font-size: 15px;
				margin-right: 14px;
			}

			p:nth-child(2),
			p:nth-child(3) {
				cursor: pointer;
			}
		}

		.searchBox {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			background-color: rgba(236, 244, 249, 0);
			z-index: 9999999999;

			.searchView {
				position: absolute;
				width: 1028px;
				height: 628px;
				background-color: #FFF;
				box-shadow: 0 0 5px 1px #999;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;

				>img {
					position: absolute;
					top: 48px;
					right: 48px;
					margin: auto;
					width: 90px;
					height: 90px;
					z-index: 9999999999;
					cursor: pointer;
				}

				>div:nth-child(1) {
					width: 620px;
					height: 134px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				>div:nth-child(2) {
					width: 800px;
					height: 100px;
					background-color: #F4F4F4;
					border-radius: 50px;
					display: flex;

					input {
						width: calc(80% - 30px);
						height: 100%;
						border: none;
						background-color: #F4F4F4;
						border-radius: 50px;
						outline: none;
						padding: 0 30px;
						font-size: 26px;
					}

					span {
						display: inline-block;
						width: 20%;
						height: 100%;
						background-color: #35A7F6;
						color: #fff;
						font-size: 30px;
						font-weight: bold;
						text-align: center;
						line-height: 100px;
						border-radius: 50px;
						cursor: pointer;
					}
				}

				>div:nth-child(3) {
					width: 720px;
					height: 100px;
					font-size: 24px;
					color: #7B7B7B;
					text-align: center;
				}
			}
		}
	}

	.mobile {
		width: 100vw;
		font-size: 450%;

		.relation {
			// display: flex;
			text-align: center;
			background-color: #fff;
			padding: 40px;
			padding-top: 80px;
			color: blue;
		}

		.navigation {
			display: flex;
			height: 200px;
			background-color: #137AC2;
			padding: 0 50px;
			position: relative;
			position: sticky;
			top: 0;
			z-index: 300;
			color: #fff;
			justify-content: space-between;

			img {
				width: 150px;
				height: 150px;
			}

			>div:nth-child(1) {
				display: flex;
				align-items: center;
			}

			>div:nth-child(2) {
				text-align: center;
				line-height: 200px;
			}

			>div:nth-child(3) {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				img {
					width: 120px;
					height: 120px;
				}
			}

			.p_navigation {
				width: 100vw;
				height: auto;
				position: absolute;
				top: 200px;
				left: 0;
				right: 0;
				margin: auto;
				z-index: 99999;
				pointer-events: auto;
				background-color: #fff;

				.el-collapse {
					min-height: calc(100vh - 52px);
					height: calc(100vh - 52px);
					overflow-y: auto;
					overflow: scroll;
				}

				a {
					width: 20%;
					height: 100%;
					color: #000;
					font-size: 1rem;
				}

				div {
					>ul {
						padding: 0 20px;

						>li {
							// border-bottom: 1px solid #eee;
							padding: 30px 0;

							a {
								width: 100%;
								display: inline-block;
							}
						}
					}
				}
			}
		}

		.mob-footer {
			background-color: #333;
			color: #fff;
			text-align: center;
			padding: 40px 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				display: inline-block;
				font-size: 70px;
				margin-bottom: 10px;
			}
		}

		.searchPage {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;

			>img {
				position: absolute;
				top: 48px;
				right: 48px;
				margin: auto;
				width: 90px;
				height: 90px;
				z-index: 9999999999;
				cursor: pointer;
			}

			>div:nth-child(1) {
				width: 100%;
				height: 320px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			>div:nth-child(2) {
				width: 100%;
				height: 200px;
				background-color: #F4F4F4;
				border-radius: 500px;
				display: flex;
				margin: 100px auto;

				input {
					width: calc(100% - 30px);
					height: 100%;
					border: none;
					background-color: #F4F4F4;
					border-radius: 500px;
					outline: none;
					padding: 0 50px;
					font-size: 72px;
				}

				span {
					display: inline-block;
					width: 30%;
					height: 100%;
					background-color: #35A7F6;
					color: #fff;
					font-size: 60px;
					font-weight: bold;
					text-align: center;
					line-height: 200px;
					border-radius: 500px;
					cursor: pointer;
				}
			}

			>div:nth-child(3) {
				width: 100%;
				height: 100px;
				font-size: 72px;
				color: #7B7B7B;
				text-align: center;
				padding-bottom: 100px;
			}
		}
	}

	/* #app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	} */
</style>